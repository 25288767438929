@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.customHover{
  transition: background-color 0.4s ease;
}
.customHover:hover{
  background-color: #EEEEEE;
}

.customHover2{
  transition: background-color 0.4s ease;
}

.customHover2:hover{
  background-color: #F6F6F6;
}

.customBodyWidth {
  transition: width 0.3s
}

@media (min-width: 768px) {
  .customBodyWidth {
    width: 100%;
  }
}

/* @media (min-width: 992px) {
  .customBodyWidth {
    width: 75%; 
  }
} */

@media (min-width: 1200px) {
  .customBodyWidth{
    width: 75%; 
    
  }
}

.customSidebarWidth {
  transition: width 0.3s;
}

@media (min-width: 468px) {
  .customSidebarWidth{
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .customSidebarWidth{
    width: 250px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }
}


@media (min-width: 992px) {
  .customSidebarWidth {
    width: 250px; 
  }
}

@media (min-width: 1200px) {
  .customSidebarWidth{
    width: 250px; 
    
  }
}

@media (min-width: 768px) {
  .customSidebarHeight{
    height: 100vh; 
  }
}

@media (min-width: 992px) {
  .customSidebarHeight {
    height: 100vh; 
  }
}

@media (min-width: 1200px) {
  .customSidebarHeight{
    height: 100vh; 
  }
}

/* ---------- sidebar active ---------- */

.active {
  color: #793FDF;
  
}

.counselorSidebarActive {
  color: #065471;

}


.thColorNavy > th{
  background-color: #2E4374;
  color: white;
}

.thColorBondi > th{
  background-color: #065471;
  color: white;
}

.thColorGrey >th{
  background-color: #526D82;
  color: white;
}

.thColorPurple >th{
  background-color: #7286D3;
  color: white;
}

.thColorDarkPurple >th{
  background-color: #65647C;
  color: white;
}

.thColorDarkGreen >th {
  background-color: #6D8B74;
  color: white;
}

.sidebarColor {
  /* background-color: #F1F6F9; */
  background-color: #F5F5F5;

  color: black;
  opacity: 0.93;
}

.navbarColor {
  /* background-color: #713ABE; */
  background-color: #2b2d42;
  opacity: 0.92;
}

.footerColor{
  background-color: #2b2d42;
  color: white;

}

.visitCardColor {
  background-color: #1C6DD0;
  color: white;
}

.customBodyShadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.customCardColor {
  background-color: #F6F6F6;
  box-shadow: 0px 0px 2px #C9CBFF;
}


.uniDropDown {
  background-color: #D6E5FA;
}


/* Cards */
.cardHeaderPurple {
  background-color: #793FDF;
  color: white;
}


/* ------------Card Customize --------- */


.cardBorderLightNavy {
  border: 2px solid #4F709C;
  border-radius: 8px;
}

.cardHeaderLightNavy {
  background-color: #4F709C;
  color: white;
}

.cardHeaderFuture {
  background-color: #F3DEBA;
  color: black;
}

.cardBorderFuture {
  border: 2px solid #F3DEBA;
  border-radius: 8px;
}

.cardHeaderNavColor {
  background-color: #2b2d42;
  color: white;
}

.cardBorderNavColor {
  border: 2px solid #2b2d42;
  border-radius: 8px;
}

/* ----------- Card 1 ----------- */
.cardBorderMaroon {
  border: 2px solid #952323; 
  border-radius: 8px;
}

.cardHeaderMaroon {
  background-color: #952323;
  color: white;
}

.cardHeaderMaroon2 {
  background-color: #C70039;
  color: white;
}
.cardBodygrey {
  background-color: #DEE2E6;
}

.cardButton1{
  background-color: #FCE22A;
  border: 0;
  color: black;
}
.cardButton1:hover{
  background-color: #F2CD5C;
  color: black;
}
.cardButton1:active{
  background-color: #F2CD5C;
  color: black;
}


/* ----------- Card 2 ----------- */



/* ------------ university selections  */
.highlighted {
  background-color: 'red'; /* Choose your desired highlight color */
}